@import url('fonts/font-awesome.css');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400&display=swap');
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    'Noto Sans', 'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New',
    monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
}

/* Flaticon */

@font-face {
  font-family: 'Flaticon';
  src: url(fonts//Flaticon.eot);
  src: url(fonts/Flaticon.eot?#iefix) format('embedded-opentype'),
    url(fonts/Flaticon.woff2) format('woff2'), url(fonts//Flaticon.woff) format('woff'),
    url(fonts/Flaticon.ttf) format('truetype'), url(fonts/Flaticon.svg) format('svg');
  font-weight: normal;
  font-style: normal;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: 'Flaticon';
    src: url(fonts/Flaticon.svg#Flaticon) format('svg');
  }
}
[class^='flaticon-']:before,
[class*=' flaticon-']:before,
[class^='flaticon-']:after,
[class*=' flaticon-']:after {
  font-family: Flaticon;
  font-size: 15px;
  font-style: normal;
}
.flaticon-wifi-signal:before {
  content: '\f100';
}
.flaticon-multimedia:before {
  content: '\f101';
}
.flaticon-wifi:before {
  content: '\f102';
}
.flaticon-speedometer:before {
  content: '\f103';
}
.flaticon-speedometer-1:before {
  content: '\f104';
}
.flaticon-download-to-storage-drive:before {
  content: '\f105';
}
.flaticon-support:before {
  content: '\f106';
}
.flaticon-arrow-pointing-to-right:before {
  content: '\f107';
}
.flaticon-care-about-environment:before {
  content: '\f108';
}
.flaticon-technical-support:before {
  content: '\f109';
}
.flaticon-router:before {
  content: '\f10a';
}
.flaticon-wifi-1:before {
  content: '\f10b';
}
.flaticon-wifi-signal-tower:before {
  content: '\f10c';
}
.flaticon-online-shop:before {
  content: '\f10d';
}
.flaticon-key:before {
  content: '\f10e';
}
.flaticon-shield:before {
  content: '\f10f';
}
.flaticon-resume:before {
  content: '\f110';
}
.flaticon-check-mark:before {
  content: '\f111';
}
.flaticon-help:before {
  content: '\f112';
}
.flaticon-add:before {
  content: '\f113';
}
.flaticon-remove:before {
  content: '\f114';
}
.flaticon-facebook:before {
  content: '\f115';
}
.flaticon-facebook-message:before {
  content: '\f116';
}
.flaticon-download-arrow:before {
  content: '\f117';
}
.flaticon-paper-plane:before {
  content: '\f118';
}
.flaticon-quote-left:before {
  content: '\f119';
}
.flaticon-place:before {
  content: '\f11a';
}
.flaticon-address-book:before {
  content: '\f11b';
}
.flaticon-signal:before {
  content: '\f11c';
}
.flaticon-pdf:before {
  content: '\f11d';
}
.flaticon-txt:before {
  content: '\f11e';
}
.main-banner-content .sub-title i:before {
  font-size: 10px;
}
.flaticon-wifi-signal:before {
  content: '\f100';
}

body {
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: var(--smoke-white);
}
::-webkit-scrollbar {
  width: 6px;
  background-color: var(--smoke-white);
  height: 6px;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--smoke-red);
}

/* navbar */
.top-header {
  background-color: #242733;
  border-bottom: 1px solid #2c303f;
  padding-top: 15px;
  padding-bottom: 15px;
}
.top-header-left {
  color: #fff;
  margin-bottom: 0;
  line-height: initial;
}
.top-header-left p a {
  display: inline-block;
  color: #fff;
  margin-left: 2px;
}
.top-header-left p a:hover {
  color: #fa8185;
}
.top-header-left p span {
  color: #e1ff19;
}
.top-header-right {
  text-align: right;
}
.top-header-right .login-signup-btn {
  display: inline-block;
  position: relative;
  padding-right: 15px;
}
.top-header-right .login-signup-btn p {
  color: #8b8b89;
}
.top-header-right .login-signup-btn p a {
  display: inline-block;
  color: #e1e1e1;
}
.top-header-right .login-signup-btn p a:hover {
  color: #e1ff19;
}
.top-header-right .login-signup-btn p span {
  display: inline-block;
  margin: 0 6px;
}
.top-header-right .login-signup-btn:before {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background: #8b8b89;
  width: 1px;
  height: 12px;
}
.top-header-right .social {
  display: inline-block;
  padding-left: 15px;
  margin-bottom: 0;
  list-style-type: none;
}
.top-header-right .social li {
  display: inline-block;
  margin-left: 5px;
}
.top-header-right .social li a {
  width: 35px;
  height: 35px;
  line-height: 37px;
  background-color: #3d4152;
  text-align: center;
  color: #fff;
  border-radius: 50%;
  font-size: 14px;
}
.top-header-right .social li a:hover {
  background-color: #fa8185;
  color: #fff;
}
.raj-mobile-nav {
  display: none;
}
.raj-mobile-nav.mean-container .mean-nav ul li a.active {
  color: #fa8185;
}
.navbar-light .navbar-brand {
  color: #ffffff;
}
.navbar-brand {
  padding-top: 0;
  padding-bottom: 0;
}
.raj-nav {
  background-color: transparent;
  padding-top: 15px;
  padding-bottom: 15px;
}
.raj-nav .navbar {
  padding: 0;
}
.raj-nav .navbar ul {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
}
.raj-nav .navbar .navbar-nav {
  font-family: 'Montserrat', sans-serif;
  margin-left: auto;
}
.raj-nav .navbar .navbar-nav .nav-item {
  position: relative;
  padding: 15px 0;
}
.raj-nav .navbar .navbar-nav .nav-item a {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  padding: 0;
  margin-left: 15px;
  margin-right: 15px;
}
.raj-nav .navbar .navbar-nav .nav-item a:hover,
.raj-nav .navbar .navbar-nav .nav-item a:focus,
.raj-nav .navbar .navbar-nav .nav-item a.active {
  color: #fa8185;
}
.raj-nav .navbar .navbar-nav .nav-item a i {
  font-size: 11px;
  position: relative;
  top: -1px;
  right: -1px;
}
.raj-nav .navbar .navbar-nav .nav-item:last-child a {
  margin-right: 0;
}
.raj-nav .navbar .navbar-nav .nav-item:first-child a {
  margin-left: 0;
}
.raj-nav .navbar .navbar-nav .nav-item:hover a,
.raj-nav .navbar .navbar-nav .nav-item:focus a,
.raj-nav .navbar .navbar-nav .nav-item.active a {
  color: #fa8185;
}
.raj-nav .navbar .navbar-nav .nav-item .dropdown-menu {
  box-shadow: 0 0 15px #0000001a;
  background: #0e101b;
  position: absolute;
  border: none;
  top: 80px;
  left: 0;
  width: 250px;
  z-index: 99;
  display: block;
  opacity: 0;
  visibility: hidden;
  border-radius: 0;
  transition: all 0.3s ease-in-out;
  padding: 20px 5px;
}
.raj-nav .navbar .navbar-nav .nav-item .dropdown-menu li {
  padding: 0;
}
.raj-nav .navbar .navbar-nav .nav-item .dropdown-menu li a {
  text-transform: capitalize;
  padding: 8px 15px;
  margin: 0;
  color: #fff;
  font-size: 15px;
}
.raj-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:hover,
.raj-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:focus,
.raj-nav .navbar .navbar-nav .nav-item .dropdown-menu li a.active {
  color: #fa8185;
}
.raj-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu {
  left: 220px;
  top: 0;
  opacity: 0;
  visibility: hidden;
}
.raj-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a {
  color: #fff;
}
.raj-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:hover,
.raj-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:focus,
.raj-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a.active {
  color: #fa8185;
}
.raj-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: 220px;
  top: 0;
  opacity: 0;
  visibility: hidden;
}
.raj-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #fff;
  text-transform: capitalize;
}
.raj-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a:hover,
.raj-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a:focus,
.raj-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a.active {
  color: #fa8185;
}
.raj-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu {
  left: -250px;
  top: 0;
  opacity: 0;
  visibility: hidden;
}
.raj-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a {
  color: #fff;
  text-transform: capitalize;
}
.raj-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a:hover,
.raj-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a:focus,
.raj-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a.active {
  color: #fa8185;
}
.raj-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu {
  left: -250px;
  top: 0;
  opacity: 0;
  visibility: hidden;
}
.raj-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a {
  color: #fff;
  text-transform: capitalize;
}
.raj-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a:hover,
.raj-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a:focus,
.raj-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a.active {
  color: #fa8185;
}
.raj-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu {
  left: -250px;
  top: 0;
  opacity: 0;
  visibility: hidden;
}
.raj-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a {
  color: #fff;
  text-transform: capitalize;
}
.raj-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a:hover,
.raj-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a:focus,
.raj-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a.active {
  color: #fa8185;
}
.raj-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu {
  left: -250px;
  top: 0;
  opacity: 0;
  visibility: hidden;
}
.raj-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a {
  color: #fff;
  text-transform: capitalize;
}
.raj-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a:hover,
.raj-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a:focus,
.raj-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a.active {
  color: #fa8185;
}
.raj-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li.active
  a {
  color: #fa8185;
}
.raj-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li:hover
  .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: -15px;
}
.raj-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li.active
  a {
  color: #fa8185;
}
.raj-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li:hover
  .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: -15px;
}
.raj-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li.active
  a {
  color: #fa8185;
}
.raj-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li:hover
  .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: -15px;
}
.raj-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li.active
  a {
  color: #fa8185;
}
.raj-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li:hover
  .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: -15px;
}
.raj-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li.active a {
  color: #fa8185;
}
.raj-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: -15px;
}
.raj-nav .navbar .navbar-nav .nav-item .dropdown-menu li.active a {
  color: #fa8185;
}
.raj-nav .navbar .navbar-nav .nav-item .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: -15px;
}
.raj-nav .navbar .navbar-nav .nav-item:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: 100%;
}
.raj-nav .navbar .others-options {
  margin-left: 30px;
}
.raj-nav .navbar .others-options .btn-primary {
  border: 1px solid #fa8185;
  background-color: transparent;
  color: #fa8185;
  padding: 13px 26px;
}
.raj-nav .navbar .others-options .btn-primary:hover,
.raj-nav .navbar .others-options .btn-primary:focus {
  background-color: transparent !important;
}
.navbar-area {
  background-color: #242733;
}
.navbar-area.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  box-shadow: 0 2px 28px #0000000f;
  background-color: #242733 !important;
  animation: 0.5s ease-in-out 0s normal none 1 running fadeInDown;
}
.header-area.header-style-two {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
  z-index: 3;
}
.header-area.header-style-two .top-header {
  background-color: transparent;
  border-bottom: none;
}
.header-area.header-style-two .top-header .top-header-right .social li a {
  background-color: #585b69;
}
.header-area.header-style-two .top-header .top-header-right .social li a:hover {
  background-color: #fa8185;
  color: #fff;
}
.header-area.header-style-two .navbar-area {
  background-color: transparent;
}
.header-area.header-style-two .navbar-area .raj-nav {
  padding-top: 0;
  padding-bottom: 0;
}
.header-area.header-style-two .navbar-area .raj-nav .navbar {
  padding-top: 15px;
  padding-bottom: 15px;
  border-top: 1px solid #4e4957;
  border-bottom: 1px solid #4e4957;
}
.header-area.header-style-two .navbar-area.is-sticky .raj-nav {
  padding-top: 15px;
  padding-bottom: 15px;
}
.header-area.header-style-two .navbar-area.is-sticky .raj-nav .navbar {
  border: none;
  padding-top: 0;
  padding-bottom: 0;
}
.mean-container .mean-bar {
  float: left;
  width: 100%;
  position: absolute;
  background: transparent;
  padding: 0;
  z-index: 999;
  border-bottom: 1px solid rgba(0, 0, 0, 0.03);
  height: 62px;
}
.mean-container a.meanmenu-reveal {
  width: 35px;
  height: 30px;
  padding: 5px 15px 0 0;
  position: absolute;
  right: 0;
  cursor: pointer;
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  text-indent: -9999em;
  line-height: 22px;
  font-size: 1px;
  display: block;
  font-weight: 700;
}
.mean-container a.meanmenu-reveal span {
  display: block;
  background: #ffffff;
  height: 4px;
  margin-top: -5px;
  border-radius: 3px;
  position: relative;
  top: 8px;
}
.mean-container .mean-nav {
  float: left;
  width: 100%;
  background: #ffffff;
  margin-top: 52px;
}
.mean-container .mean-nav ul {
  padding: 0;
  margin: 0;
  width: 100%;
  border: none;
  list-style-type: none;
}
.mean-container .mean-nav ul li {
  position: relative;
  float: left;
  width: 100%;
}
.mean-container .mean-nav ul li a i {
  display: none;
}
.mean-container .mean-nav ul li a {
  display: block;
  float: left;
  width: 90%;
  padding: 1em 5%;
  margin: 0;
  text-align: left;
  color: #677294;
  border-top: 1px solid #dbeefd;
  text-decoration: none;
}
.mean-container .mean-nav ul li a.active {
  color: #000;
}
.mean-container .mean-nav ul li li a {
  width: 80%;
  padding: 1em 10%;
  color: #677294;
  border-top: 1px solid #dbeefd;
  opacity: 1;
  filter: alpha(opacity=75);
  text-shadow: none !important;
  visibility: visible;
  text-transform: none;
  font-size: 14px;
}
.mean-container .mean-nav ul li.mean-last a {
  border-bottom: 1px solid #dbeefd;
  margin-bottom: 0;
}
.mean-container .mean-nav ul li li li a {
  width: 70%;
  padding: 1em 15%;
}
.mean-container .mean-nav ul li li li li a {
  width: 60%;
  padding: 1em 20%;
}
.mean-container .mean-nav ul li li li li li a {
  width: 50%;
  padding: 1em 25%;
}
.mean-container .mean-nav ul li a:hover {
  background: #252525;
  background: rgba(255, 255, 255, 0.1);
}
.mean-container .mean-nav ul li a.mean-expand {
  margin-top: 1px;
  width: 100%;
  height: 24px;
  padding: 12px !important;
  text-align: right;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  font-weight: 700;
  background: transparent;
  border: none !important;
}
.mean-container .mean-push {
  float: left;
  width: 100%;
  padding: 0;
  margin: 0;
  clear: both;
}
.mean-nav .wrapper {
  width: 100%;
  padding: 0;
  margin: 0;
}
.mean-container .mean-bar,
.mean-container .mean-bar * {
  box-sizing: content-box;
}
.mean-remove {
  display: none !important;
}

/* homePage */
/* main_banner */

.main-banner {
  position: relative;
  z-index: 1;
  background-color: #fcfcfc;
  background-image: url('/public/img/black-bg1.d35d9682650f5233.png');
  background-position: bottom left;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 80px;
  padding-bottom: 90px;
}

.main-banner .container {
  position: relative;
}
.align-items-center {
  align-items: center !important;
}

.main-banner-content .sub-title {
  display: inline-block;
  margin-bottom: 16px;
  color: #fff;
  font-size: 17px;
}
.main-banner-content .sub-title i {
  background-color: #e1ff19;
  color: #0e101b;
  width: 25px;
  height: 25px;
  display: inline-block;
  text-align: center;
  border-radius: 7px;
  margin-right: 2px;
  line-height: 25px;
}

.main-banner-content h1 {
  color: #fff;
  margin-bottom: 15px;
  line-height: 1.1;
  font-size: 70px;
  font-weight: 700;
}
.main-banner-content h1 span {
  color: #fa8185;
}
.main-banner-content p {
  color: #fff;
  margin-bottom: 0;
  font-size: 16px;
}
.main-banner-content .price {
  color: #e1ff19;
  margin-top: 8px;
  font-size: 65px;
  font-weight: 700;
}
.main-banner-content .btn-box {
  margin-top: 17px;
}
.banner-image img {
  max-width: 115%;
}
.shape-img1 {
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}
img {
  max-width: 100%;
}
img,
svg {
  vertical-align: middle;
}
.features-area.bg-image {
  background-color: #fcfcfc;
}
.features-area {
  padding-bottom: 70px;
}
.ptb-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}
.section-title {
  text-align: center;
  max-width: 700px;
  margin-bottom: 60px;
  margin-left: auto;
  margin-right: auto;
}
.section-title span {
  display: inline-block;
  color: #fa8185;
  font-size: 19px;
  margin-bottom: 5px;
}
.section-title span .icon {
  background-color: #fce3e4;
  color: #fa8185;
  width: 30px;
  height: 30px;
  border-radius: 7px;
  font-size: 15px;
  line-height: 30px;
  display: inline-block;
  margin-top: 0;
}

.section-title span span {
  display: block;
  margin-bottom: 0;
  margin-top: 13px;
}
.section-title span {
  display: inline-block;
  color: #fa8185;
  font-size: 19px;
  margin-bottom: 5px;
}
.section-title span .icon {
  background-color: #fce3e4;
  color: #fa8185;
  width: 30px;
  height: 30px;
  border-radius: 7px;
  font-size: 15px;
  line-height: 30px;
  display: inline-block;
  margin-top: 0;
}
.section-title h2 {
  margin-bottom: 0;
  font-size: 38px;
  font-weight: 700;
}
.single-features-box {
  text-align: center;
  margin-bottom: 30px;
  padding: 25px;
  background-color: #fff;
  box-shadow: 0 14px 28px #00000012, 0 10px 10px #0000000d;
  border-radius: 5px;
  transition: 0.5s;
  overflow: hidden;
  position: relative;
  z-index: 1;
}
.single-features-box .icon {
  color: #fa8185;
  margin-bottom: 10px;
}
.single-features-box h3 {
  transition: 0.5s;
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: 700;
}
.single-features-box p {
  margin-bottom: 0;
  transition: 0.5s;
}

p {
  line-height: 1.9;
  color: #666;
  margin-bottom: 15px;
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}
.single-features-box .back-icon {
  color: #fa8185;
  opacity: 0.05;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  margin: 0 auto;
  left: 0;
  z-index: -1;
  transition: 0.5s;
}
.single-features-box .details-btn {
  margin-top: 18px;
}

.details-btn {
  display: inline-block;
  position: relative;
  color: #c2c2c2;
  z-index: 1;
  width: 35px;
  height: 35px;
  line-height: 36px;
  left: -9px;
  border-radius: 50%;
}
a {
  display: block;
  color: #0e101b;
  text-decoration: none;
  transition: 0.5s;
}
a {
  color: #0d6efd;
  text-decoration: underline;
}
.single-features-box .image-box img:nth-child(1) {
  top: 0;
  right: 0;
  transform: rotate(180deg);
}
.single-features-box .image-box img {
  position: absolute;
  max-width: 60%;
  transition: 0.5s;
  opacity: 0;
  z-index: -1;
  visibility: hidden;
}
.single-features-box:hover .image-box img {
  opacity: 1;
  visibility: visible;
}
.single-features-box:hover {
  transform: translateY(-5px);
  border-radius: 0;
  background-color: #20222e;
}
.single-features-box:hover h3,
.single-features-box:hover p {
  color: #fff;
}

.single-features-box .image-box img:nth-child(1) {
  top: 0;
  right: 0;
  transform: rotate(180deg);
}
*,
*:before,
*:after {
  box-sizing: border-box;
}

/* about */

.about-content span {
  display: inline-block;
  color: #fa8185;
  font-size: 18px;
  margin-bottom: 15px;
}
.about-content span i {
  background-color: #fce3e4;
  color: #fa8185;
  width: 30px;
  height: 30px;
  border-radius: 7px;
  line-height: 30px;
  text-align: center;
  display: inline-block;
  margin-right: 4px;
}
.about-content span i:before {
  font-size: 12px;
}
.about-content h2 {
  margin-bottom: 15px;
  font-size: 38px;
  font-weight: 700;
}
.about-content p {
  margin-bottom: 15px;
}
.about-content p:last-child {
  margin-bottom: 0;
}
.about-content .btn {
  margin-top: 5px;
}
.about-image {
  text-align: center;
}
.about-main-image {
  position: relative;
  text-align: center;
  z-index: 1;
  padding-left: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
}
.about-main-image:before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 87%;
  z-index: -1;
  background: #fa8185;
}
.about-inner-area {
  margin-bottom: 60px;
}
.single-about-box {
  text-align: center;
  margin-bottom: 30px;
  padding: 25px 20px;
  background-color: #fff8f8;
  transition: 0.5s;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.single-about-box .icon {
  color: #0e101b;
  margin-bottom: 20px;
  background-color: #fdd7d8;
  display: inline-block;
  width: 75px;
  height: 75px;
  line-height: 78px;
  border-radius: 50%;
  transition: 0.5s;
}
.single-about-box .icon i:before {
  font-size: 30px;
}
.single-about-box h3 {
  transition: 0.5s;
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: 700;
}
.single-about-box p {
  margin-bottom: 0;
  transition: 0.5s;
}
.single-about-box:hover {
  background-color: #0e101b;
  box-shadow: 0 14px 28px #00000012, 0 10px 10px #0000000d;
}
.single-about-box:hover h3,
.single-about-box:hover p {
  color: #fff;
}
.single-about-box:hover .icon {
  background-color: #e1ff19;
}

/* services */
.services-area {
  position: relative;
  z-index: 1;
  padding-bottom: 70px;
}
.services-area.bg-image {
  background-color: #20222e;
}
.services-area.bg-image .section-title h2 {
  color: #fff;
}
.single-services-box {
  margin-bottom: 30px;
  text-align: center;
  transition: 0.5s;
  padding: 25px;
  border: 2px solid #35384a;
}
.single-services-box .icon {
  color: #e1ff19;
  margin-bottom: 10px;
}
.single-services-box .icon i:before {
  font-size: 40px;
}
.single-services-box h3 {
  margin-bottom: 10px;
  color: #fff;
  font-size: 24px;
  font-weight: 700;
}
.single-services-box p {
  margin-bottom: 0;
  color: #d7d7d7;
}
.single-services-box .read-more-btn {
  margin-top: 15px;
  display: inline-block;
  color: #e1ff19;
  font-weight: 600;
}
.single-services-box .read-more-btn i {
  margin-left: 1px;
  transition: 0.5s;
}
.single-services-box .read-more-btn i:before {
  font-size: 13px;
}
.single-services-box .read-more-btn:hover i {
  margin-left: 5px;
}
.single-services-box:hover {
  border-color: #e1ff19;
}
.services-box {
  text-align: center;
  margin-bottom: 30px;
  padding: 25px;
  background-color: #fff;
  box-shadow: 0 14px 28px #00000012, 0 10px 10px #0000000d;
  transition: 0.5s;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.services-box .icon {
  color: #fa8185;
  margin-bottom: 20px;
  background-color: #fee5e6;
  display: inline-block;
  width: 75px;
  height: 75px;
  line-height: 78px;
  border-radius: 50%;
  transition: 0.5s;
}
.services-box .icon i:before {
  font-size: 30px;
}
.services-box h3 {
  transition: 0.5s;
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: 700;
}
.services-box p {
  margin-bottom: 0;
  transition: 0.5s;
}
.services-box .details-btn {
  margin-top: 18px;
}
.services-box .details-btn:hover {
  background-color: #fff;
  color: #fa8185 !important;
}
.services-box .image-box img {
  position: absolute;
  z-index: -1;
  max-width: 50%;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s;
}
.services-box .image-box img:nth-child(1) {
  top: -20px;
  right: -20px;
}
.services-box .image-box img:nth-child(2) {
  bottom: -20px;
  left: -20px;
  transform: rotate(180deg);
}
.services-box:hover {
  background-color: #0e101b;
}
.services-box:hover h3,
.services-box:hover p {
  color: #fff;
}
.services-box:hover .details-btn {
  color: #fff;
}
.services-box:hover .details-btn:before {
  border-color: #fff;
}
.services-box:hover .image-box img {
  opacity: 1;
  visibility: visible;
}
.services-box:hover .image-box img:nth-child(1) {
  top: 0;
  right: 0;
}
.services-box:hover .image-box img:nth-child(2) {
  bottom: 0;
  left: 0;
}
.services-details .services-details-desc {
  margin-top: 25px;
}
.services-details .services-details-desc h3 {
  margin-bottom: 15px;
  line-height: 1.5;
  font-size: 24px;
  font-weight: 700;
}
.services-details .services-details-desc h3:not(:first-child) {
  margin-top: 20px;
}
.services-details .services-details-desc .services-details-faq {
  margin-top: 25px;
}
.services-details .services-details-desc .services-details-faq .accordion {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.services-details .services-details-desc .services-details-faq .accordion .accordion-item {
  display: block;
  margin-bottom: 15px;
  border: 0;
}
.services-details
  .services-details-desc
  .services-details-faq
  .accordion
  .accordion-item:last-child {
  margin-bottom: 0;
}
.services-details
  .services-details-desc
  .services-details-faq
  .accordion
  .accordion-item
  .accordion-title {
  color: #0e101b;
  position: relative;
  padding-left: 21px;
  font-size: 17px;
  font-weight: 600;
}
.services-details
  .services-details-desc
  .services-details-faq
  .accordion
  .accordion-item
  .accordion-title
  i {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-52%);
  font-size: 14px;
}
.services-details
  .services-details-desc
  .services-details-faq
  .accordion
  .accordion-item
  .accordion-title:hover {
  color: #fa8185;
}
.services-details
  .services-details-desc
  .services-details-faq
  .accordion
  .accordion-item
  .accordion-content {
  display: none;
  margin-top: 8px;
}
.services-details
  .services-details-desc
  .services-details-faq
  .accordion
  .accordion-item.active
  .accordion-title {
  color: #fa8185;
}
.services-details
  .services-details-desc
  .services-details-faq
  .accordion
  .accordion-item.active
  .accordion-content {
  display: block;
}
.services-sidebar .services-list {
  box-shadow: 0 2px 28px #0000000f;
  background-color: #fff;
  padding: 20px;
  margin-bottom: 20px;
}
.services-sidebar .services-list ul {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
}
.services-sidebar .services-list ul li {
  margin-bottom: 7px;
}
.services-sidebar .services-list ul li a {
  font-weight: 700;
  padding: 10px 12px;
  background-color: #f2f2f2;
  color: #0e101b;
  position: relative;
}
.services-sidebar .services-list ul li a i {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
}
.services-sidebar .services-list ul li a:hover,
.services-sidebar .services-list ul li a.active {
  background-color: #fa8185;
  color: #fff;
  box-shadow: 0 2px 28px #0000000f;
}
.services-sidebar .services-download-list ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.services-sidebar .services-download-list ul li {
  margin-bottom: 10px;
}
.services-sidebar .services-download-list ul li a {
  background-color: #fff;
  box-shadow: 0 2px 28px #0000000f;
  font-weight: 700;
  padding: 14px 15px;
}
.services-sidebar .services-download-list ul li a:hover {
  background-color: #fa8185;
  color: #fff;
}
.services-sidebar .services-download-list ul li a i {
  margin-right: 2px;
}
.services-sidebar .services-download-list ul li:last-child {
  margin-bottom: 0;
}

.team-area {
  padding-bottom: 70px;
}
.single-team-member {
  margin-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: #f7f6fb;
}
.single-team-member .member-image {
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
}
.single-team-member .member-content {
  flex: 0 0 66.6667%;
  max-width: 66.6667%;
  padding-left: 30px;
}
.single-team-member .member-content h3 {
  margin-bottom: 7px;
  font-size: 24px;
  font-weight: 700;
}
.single-team-member .member-content span {
  display: block;
  color: #fa8185;
}
.single-team-member .member-content .info {
  padding-left: 0;
  list-style-type: none;
  margin-top: 20px;
  margin-bottom: 0;
}
.single-team-member .member-content .info li {
  margin-bottom: 12px;
  color: #666;
}
.single-team-member .member-content .info li span {
  display: inline-block;
  color: #0e101b;
  font-weight: 700;
}
.single-team-member .member-content .info li a {
  display: inline-block;
  color: #666;
}
.single-team-member .member-content .info li a:hover {
  color: #fa8185;
}
.single-team-member .member-content .info li:last-child {
  margin-bottom: 0;
}
.single-team-member .member-content .social {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
  margin-top: 15px;
}
.single-team-member .member-content .social li {
  margin-right: 5px;
  display: inline-block;
}
.single-team-member .member-content .social li a {
  width: 35px;
  height: 35px;
  line-height: 35px;
  border: 1px solid #dddddd;
  border-radius: 50%;
  color: #0e101b;
  font-size: 14px;
  text-align: center;
}
.single-team-member .member-content .social li a:hover {
  background-color: #fa8185;
  color: #fff;
  border-color: #fa8185;
}
.single-team-member .member-content .social li:last-child {
  margin-right: 0;
}

/* contacts */
.contact-info-box-area {
  padding-top: 70px;
}
.single-contact-info {
  text-align: center;
  margin-top: 30px;
  padding: 30px 20px;
  background-color: #fff;
  box-shadow: 0 14px 28px #00000012, 0 10px 10px #0000000d;
  transition: 0.5s;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.single-contact-info .icon {
  color: #fa8185;
  margin-bottom: 20px;
  background-color: #fee5e6;
  display: inline-block;
  width: 75px;
  height: 75px;
  line-height: 78px;
  border-radius: 50%;
  transition: 0.5s;
}
.single-contact-info .icon i:before {
  font-size: 30px;
}
.single-contact-info h3 {
  transition: 0.5s;
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: 700;
}
.single-contact-info p {
  margin-bottom: 0;
  transition: 0.5s;
}
.single-contact-info p a {
  color: #666;
}
.single-contact-info p a:hover {
  color: #e1ff19 !important;
}
.single-contact-info .image-box img {
  position: absolute;
  z-index: -1;
  max-width: 50%;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s;
}
.single-contact-info .image-box img:nth-child(1) {
  top: -20px;
  right: -20px;
}
.single-contact-info .image-box img:nth-child(2) {
  bottom: -20px;
  left: -20px;
  transform: rotate(180deg);
}
.single-contact-info:hover {
  background-color: #fa8185;
}
.single-contact-info:hover h3,
.single-contact-info:hover p {
  color: #fff;
}
.single-contact-info:hover h3 a,
.single-contact-info:hover p a {
  color: #fff;
}
.single-contact-info:hover .icon {
  color: #0e101b;
  background-color: #e1ff19;
}
.single-contact-info:hover .image-box img {
  opacity: 0.15;
  visibility: visible;
}
.single-contact-info:hover .image-box img:nth-child(1) {
  top: 0;
  right: 0;
}
.single-contact-info:hover .image-box img:nth-child(2) {
  bottom: 0;
  left: 0;
}
#contactForm {
  max-width: 950px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
#contactForm .form-control {
  background-color: #f9f9f9;
  height: 50px;
  text-align: left;
  padding-left: 15px;
}
#contactForm .form-control::placeholder {
  color: #909090;
}
#contactForm textarea.form-control {
  height: auto;
  padding-top: 15px;
  padding-left: 15px;
}
#contactForm .btn {
  margin-top: 15px;
}
#map iframe {
  width: 100%;
  height: 500px;
  border: none;
  margin-bottom: -7px;
}
.error-content {
  text-align: center;
  margin: 0 auto;
  max-width: 700px;
}
.error-content h3 {
  font-size: 35px;
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 17px;
}
.error-content p {
  max-width: 520px;
  margin: 0 auto 20px;
}

/* blog */

.blog-area {
  padding-bottom: 70px;
}
.blog-area .pagination-area {
  margin-bottom: 30px;
}
.single-blog-post {
  margin-bottom: 30px;
  background: #ffffff;
  box-shadow: 0 0 1.25rem #6c76861a;
}
.single-blog-post .post-image {
  overflow: hidden;
}
.single-blog-post .post-image img {
  transition: 0.5s;
}
.single-blog-post .blog-post-content {
  padding: 25px;
}
.single-blog-post .blog-post-content ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.single-blog-post .blog-post-content ul li {
  display: inline-block;
  margin-right: 15px;
  font-size: 15px;
  color: #666;
}
.single-blog-post .blog-post-content ul li:last-child {
  margin-right: 0;
}
.single-blog-post .blog-post-content ul li i {
  color: #fa8185;
  margin-right: 3px;
}
.single-blog-post .blog-post-content ul li a {
  display: inline-block;
  color: #666;
  font-weight: 600;
}
.single-blog-post .blog-post-content ul li a:hover {
  color: #fa8185;
}
.single-blog-post .blog-post-content h3 {
  margin-top: 13px;
  margin-bottom: 10px;
  line-height: 32px;
  font-size: 22px;
  font-weight: 700;
}
.single-blog-post .blog-post-content .read-more-btn {
  font-weight: 700;
}
.single-blog-post:hover .post-image img {
  transform: scale(1.3);
}
.blog-slides.owl-theme .owl-nav.disabled + .owl-dots {
  line-height: 0.01;
  margin-top: 20px;
  margin-bottom: 30px;
}
.blog-slides.owl-theme .owl-dots .owl-dot {
  outline: 0 !important;
}
.blog-slides.owl-theme .owl-dots .owl-dot span {
  width: 15px;
  height: 15px;
  margin: 0 4px;
  background: #dddddd;
  border-radius: 50%;
  transition: 0.5s;
}
.blog-slides.owl-theme .owl-dots .owl-dot:hover span,
.blog-slides.owl-theme .owl-dots .owl-dot.active span {
  background: #fa8185;
}
.blog-details .article-content {
  margin-top: 30px;
}
.blog-details .article-content .entry-meta {
  margin-bottom: 17px;
}
.blog-details .article-content .entry-meta ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.blog-details .article-content .entry-meta ul li {
  margin: 0 10px;
  position: relative;
  display: inline-block;
  color: #666;
}
.blog-details .article-content .entry-meta ul li a {
  display: inline-block;
  color: #666;
}
.blog-details .article-content .entry-meta ul li a:hover {
  color: #fa8185;
}
.blog-details .article-content .entry-meta ul li i {
  color: #fa8185;
  font-size: 16px;
  margin-right: 2px;
}
.blog-details .article-content .entry-meta ul li:before {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -15px;
  width: 6px;
  height: 1px;
  background: #666666;
}
.blog-details .article-content .entry-meta ul li:last-child {
  margin-right: 0;
}
.blog-details .article-content .entry-meta ul li:last-child:before {
  display: none;
}
.blog-details .article-content .entry-meta ul li:first-child {
  margin-left: 0;
}
.blog-details .article-content h3 {
  margin-bottom: 15px;
  font-size: 24px;
  font-weight: 700;
}
.blog-details .article-content .wp-block-gallery.columns-3 {
  padding-left: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  margin: 30px -15px;
}
.blog-details .article-content .wp-block-gallery.columns-3 li {
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
  padding-right: 15px;
  padding-left: 15px;
}
.blog-details .article-content .wp-block-gallery.columns-3 li figure {
  margin-bottom: 0;
}
.blog-details .article-content .article-features-list {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 15px;
  margin-left: 20px;
}
.blog-details .article-content .article-features-list li {
  margin-bottom: 12px;
  color: #666;
  position: relative;
  padding-left: 15px;
}
.blog-details .article-content .article-features-list li:before {
  background: #0e101b;
  height: 7px;
  width: 7px;
  content: '';
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  position: absolute;
}
.blog-details .article-content .article-features-list li:last-child {
  margin-bottom: 0;
}
.blog-details .article-footer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
}
.blog-details .article-footer .article-tags {
  flex: 0 0 50%;
  max-width: 50%;
}
.blog-details .article-footer .article-tags span {
  display: inline-block;
  color: #0e101b;
  font-size: 20px;
  margin-right: 5px;
  position: relative;
  top: 2px;
}
.blog-details .article-footer .article-tags a {
  display: inline-block;
  color: #666;
}
.blog-details .article-footer .article-tags a:hover {
  color: #fa8185;
}
.blog-details .article-footer .article-share {
  flex: 0 0 50%;
  max-width: 50%;
}
.blog-details .article-footer .article-share .social {
  padding-left: 0;
  list-style-type: none;
  text-align: right;
  margin-bottom: 0;
}
.blog-details .article-footer .article-share .social li {
  display: inline-block;
}
.blog-details .article-footer .article-share .social li a i {
  color: #fff;
  background-color: #959c98;
  width: 33px;
  height: 33px;
  line-height: 34px;
  text-align: center;
  border-radius: 50%;
  font-size: 13px;
  transition: 0.5s;
}
.blog-details .article-footer .article-share .social li a i.fa-facebook-f {
  background-color: #3b5998;
  color: #fff;
}
.blog-details .article-footer .article-share .social li a i.fa-twitter {
  background-color: #1da1f2;
  color: #fff;
}
.blog-details .article-footer .article-share .social li a i.fa-linkedin-in {
  background-color: #0077b5;
  color: #fff;
}
.blog-details .article-footer .article-share .social li a i.fa-instagram {
  background-color: #c13584;
  color: #fff;
}
.blog-details .article-footer .article-share .social li a i:hover {
  color: #fff;
  background-color: #fa8185;
}
.blog-details .post-navigation {
  margin-top: 30px;
}
.post-navigation {
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  padding-top: 20px;
  padding-bottom: 20px;
}
.post-navigation .navigation-links {
  display: flex;
  flex-wrap: wrap;
}
.post-navigation .navigation-links .nav-previous {
  flex: 0 0 50%;
  max-width: 50%;
}
.post-navigation .navigation-links .nav-previous a i {
  margin-right: 2px;
}
.post-navigation .navigation-links .nav-next {
  flex: 0 0 50%;
  max-width: 50%;
  text-align: right;
}
.post-navigation .navigation-links .nav-next a i {
  margin-left: 2px;
}
.post-navigation .navigation-links div a {
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
}
blockquote,
.blockquote {
  overflow: hidden;
  background-color: #fafafa;
  padding: 50px !important;
  position: relative;
  text-align: center;
  z-index: 1;
  margin-bottom: 20px;
  margin-top: 20px;
}
blockquote p,
.blockquote p {
  color: #0e101b;
  line-height: 1.6;
  margin-bottom: 0;
  font-style: italic;
  font-weight: 600;
  font-size: 20px;
}
blockquote cite,
.blockquote cite {
  display: none;
}
blockquote:before,
.blockquote:before {
  color: #efefef;
  content: '\f119';
  position: absolute;
  left: 50px;
  top: -50px;
  z-index: -1;
  font-family: Flaticon;
  font-size: 140px;
  font-style: normal;
}

/* footer */
.footer-area {
  background-color: #20222e;
  /* padding-top: 100px; */
}

.footer-style-two .copyright-area {
  text-align: right;
}
.copyright-area {
  border-top: 1px solid #292b38;
  text-align: center;
  margin-top: 60px;
  padding-top: 30px;
  padding-bottom: 30px;
}
.copyright-area p {
  margin-bottom: 0;
  color: #d7d7d7;
}
.copyright-area .social {
  text-align: left;
  margin-bottom: 0;
  list-style-type: none;
  padding-left: 0;
}
.copyright-area .social li {
  display: inline-block;
  margin-right: 5px;
}
.copyright-area .social li:last-child {
  margin-right: 0;
}
.copyright-area .social li a {
  width: 35px;
  height: 35px;
  line-height: 37px;
  background-color: #0e101b;
  text-align: center;
  color: #fff;
  border-radius: 50%;
  font-size: 14px;
}
.copyright-area .social li a:hover {
  background-color: #fa8185;
  color: #fff;
}

p {
  line-height: 1.9;
  color: #666;
  margin-bottom: 15px;
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
}
p:last-child {
  margin-bottom: 0;
}
a {
  display: block;
  color: #0e101b;
  text-decoration: none;
  transition: 0.5s;
}
a:hover {
  color: #fa8185;
  text-decoration: none;
}
.bg-fcfcfc {
  background-color: #fcfcfc;
}

.our-coverage-area {
  position: relative;
  z-index: 1;
  background-color: #20222e;
}
.coverage-area-content span {
  display: inline-block;
  color: #fa8185;
  font-size: 18px;
  margin-bottom: 15px;
}

.coverage-area-content span i {
  background-color: #fce3e4;
  color: #fa8185;
  width: 30px;
  height: 30px;
  border-radius: 7px;
  line-height: 30px;
  text-align: center;
  display: inline-block;
  margin-right: 4px;
}
.coverage-area-content span i:before {
  font-size: 12px;
}
.coverage-area-content h2 {
  color: #fff;
  margin-bottom: 15px;
  font-size: 38px;
  font-weight: 700;
}
.coverage-area-content p {
  margin-bottom: 15px;
  color: #fff;
}
.coverage-area-content p:last-child {
  margin-bottom: 0;
}

.btn {
  border: none;
  padding: 15px 30px;
  line-height: initial;
  border-radius: 30px;
  transition: 0.5s;
  position: relative;
  z-index: 1;
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  font-weight: 700;
}
.coverage-area-content .btn-primary {
  background-color: #e1ff19;
  color: #0e101b;
}
.coverage-area-content .btn-primary:after,
.coverage-area-content .btn-primary:before {
  background: #e1ff19;
  box-shadow: unset !important;
  border-color: #e1ff19 !important;
}
.coverage-area-content .btn-primary:hover,
.coverage-area-content .btn-primary:focus {
  background-color: #fa8185 !important;
  color: #fff !important;
  box-shadow: unset !important;
  border-color: #fa8185 !important;
}
.coverage-area-content .btn {
  margin-top: 5px;
}
/* plan */
.banner-image .plan-banner {
  width: 82%;
}
.ott-features span {
  color: #fff;
  padding-right: 5px;
}
.ott-img-icon {
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-size: cover;
  background-position: top center;
}
.pricing-area {
  padding-bottom: 70px;
}
.pricing-icon {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.pricing-icon .icon {
  margin-right: 20px;
}
.price-card-height {
  margin-bottom: 20px;
}
.price-card-height .single-pricing-table {
  height: 100%;
}
.pricing-features-height {
  height: 230px;
}
.single-pricing-table {
  overflow: hidden;
  margin-bottom: 30px;
  position: relative;
  box-shadow: 0 14px 28px #00000012, 0 10px 10px #0000000d;
  z-index: 1;
  background-color: #fa8185;
  transition: 0.5s;
  padding: 45px;
}
.single-pricing-table .pricing-header {
  position: relative;
  z-index: 1;
  margin-bottom: 25px;
}
.single-pricing-table .pricing-header .icon {
  display: inline-block;
  background-color: #ffabae;
  color: #0e101b;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  border-radius: 50%;
  transition: 0.5s;
  margin-bottom: 0px;
}
.single-pricing-table .pricing-header .icon i:before {
  font-size: 30px;
}
.single-pricing-table .pricing-header span {
  display: block;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 3px;
}
.single-pricing-table .pricing-header h3 {
  margin-bottom: 0;
  color: #fff;
  font-size: 24px;
  font-weight: 700;
}
.single-pricing-table .pricing-features-list {
  position: relative;
  z-index: 1;
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
}
.single-pricing-table .pricing-features-list li {
  color: #fff;
  margin-bottom: 10px;
}
.single-pricing-table .pricing-features-list li i {
  background-color: #fb898d;
  display: inline-block;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  text-align: center;
  line-height: 22px;
  transition: 0.5s;
}
.single-pricing-table .pricing-features-list li i:before {
  font-size: 9px;
}
.single-pricing-table .price {
  position: relative;
  z-index: 1;
  margin-top: 20px;
  color: #fff;
  font-size: 30px;
  font-weight: 700;
}
.single-pricing-table .price span:nth-child(1) {
  display: block;
  color: #e1ff19;
  margin-bottom: -5px;
  position: relative;
  left: 3px;
  font-size: 16px;
  font-weight: normal;
}
.single-pricing-table .price span:nth-child(2) {
  position: relative;
  right: -4px;
  bottom: 1px;
  font-size: 14px;
  font-weight: normal;
}
.single-pricing-table .price span:nth-child(3) {
  position: relative;
  left: -5px;
  font-size: 16px;
  font-weight: normal;
}
.single-pricing-table .view-plans-btn {
  position: relative;
  z-index: 1;
  margin-top: 25px;
  display: inline-block;
  padding: 11px 30px;
  color: #fff;
  border-radius: 30px;
  border: 2px solid #ffabae;
  font-size: 15px;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
}
.single-pricing-table .view-plans-btn:hover {
  background-color: #fa8185 !important;
  border-color: #fa8185 !important;
  color: #fff !important;
}
.single-pricing-table:before {
  z-index: -1;
  background: #fa8185;
  width: 60%;
  height: 100%;
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  transition: 0.5s;
}
.single-pricing-table:after {
  z-index: -1;
  background: #fa8185;
  width: 60%;
  height: 100%;
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.5s;
}
.single-pricing-table .image-box img {
  position: absolute;
  max-width: 90%;
  opacity: 0.09;
  transition: 0.5s;
}
.single-pricing-table .image-box img:nth-child(1) {
  top: 0;
  right: 0;
  transform: rotate(180deg);
}
.single-pricing-table .image-box img:nth-child(2) {
  bottom: 0;
  left: 0;
}
.single-pricing-table:hover,
.single-pricing-table.active {
  background-color: #21232f;
}
.single-pricing-table:hover:before,
.single-pricing-table:hover:after,
.single-pricing-table.active:before,
.single-pricing-table.active:after {
  width: 0;
}
.single-pricing-table:hover .icon,
.single-pricing-table.active .icon {
  background-color: #e1ff19;
}
.single-pricing-table:hover .pricing-features-list li i,
.single-pricing-table.active .pricing-features-list li i {
  background-color: #393d4b;
}
.single-pricing-table:hover .view-plans-btn,
.single-pricing-table.active .view-plans-btn {
  background-color: #e1ff19;
  border-color: #e1ff19;
  color: #0e101b;
}
.pricing-about-content span {
  display: inline-block;
  color: #fa8185;
  font-size: 18px;
  margin-bottom: 5px;
}
.pricing-about-content h2 {
  margin-bottom: 13px;
  font-size: 38px;
  font-weight: 700;
}
.pricing-about-content p {
  margin-bottom: 15px;
}
.pricing-about-content p:last-child {
  margin-bottom: 0;
}
.pricing-about-content .btn {
  margin-top: 5px;
}
.pricing-about-content .btn-primary {
  background-color: #fa8185;
  color: #fff;
}
.pricing-about-content .btn-primary:before,
.pricing-about-content .btn-primary:after {
  background: #e1ff19;
}
.pricing-about-content .btn-primary:hover,
.pricing-about-content .btn-primary:focus {
  color: #0e101b !important;
  background-color: #fa8185 !important;
}
.pricing-about-image {
  position: relative;
  text-align: center;
  z-index: 1;
  padding-left: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
}
.pricing-about-image:before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 87%;
  z-index: -1;
  background: #fa8185;
}

/* Home button */
.btn-primary {
  background-color: #e1ff19;
  color: #0e101b;
  box-shadow: unset;
}
.btn-primary:after,
.btn-primary:before {
  background: #e1ff19;
  box-shadow: unset;
}
.btn-primary:hover,
.btn-primary:focus {
  background-color: #fa8185 !important;
  color: #fff !important;
  box-shadow: unset;
}
.single-features-box .icon i:before {
  font-size: 40px;
}

/* Pricing */
.price-card-action {
  display: flex;
  cursor: pointer;
}

.pricing-plans .single-pricing-table {
  border: 3px solid #21232f;
  border-radius: 5px;
}
.pricing-plans .single-pricing-table:hover {
  border: 3px solid #e1ff19;
}

.about-details {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.about-details .icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (min-width: 1199px) and (max-width: 1442px) {
  .banner-image img {
    max-width: 108%;
  }
  .why-choose-us-image img {
    max-width: 107%;
  }
  .coverage-area-map img {
    max-width: 105%;
  }
}
@media only screen and (min-width: 1550px) {
  .home-slides.owl-theme .owl-nav {
    right: 395px;
    bottom: 170px;
  }
}
@media only screen and (max-width: 991px) {
  .raj-mobile-nav {
    display: block;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .raj-mobile-nav .logo {
    position: relative;
    z-index: 9910;
    width: 60%;
    left: 15px;
  }
  .raj-mobile-nav .logo a {
    display: inline-block;
  }
  .raj-mobile-nav.mean-container .mean-nav .navbar-nav {
    height: 343px;
    overflow-y: scroll;
  }
  .raj-nav {
    display: none;
  }
  .navbar-area.is-sticky .mean-container .mean-bar {
    border-bottom: none;
  }
  .navbar-area.is-sticky .mean-container .mean-nav {
    margin-top: 56px;
  }
  .navbar-area.is-sticky .raj-mobile-nav {
    padding-top: 15px;
    padding-bottom: 16px;
  }
  .navbar-area.is-sticky .raj-mobile-nav.mean-container a.meanmenu-reveal {
    padding: 9px 15px 0 0;
  }
  .navbar-area .mean-container .mean-nav ul li a {
    font-size: 16px;
  }
  .navbar-area .mean-container .mean-nav ul li li a {
    font-size: 15px;
  }
  .others-options {
    display: none;
  }
}
